export function Hulk() {
   return (
      <ul className="planSpecs hulk">
         <li>
            <span className="specsTitle">ROI</span>
            <span className="specsValue">5.0% DAILY</span>
         </li>
         <li>
            <span className="specsTitle">Minimum Deposit</span>
            <span className="specsValue">3.0 BNB</span>
         </li>
         <li>
            <span className="specsTitle">Maximum Deposit</span>
            <span className="specsValue">70 BNB</span>
         </li>
         <li>
            <span className="specsTitle">Maximum ROI</span>
            <span className="specsValue">135%</span>
         </li>
         <li>
            <span className="specsTitle">Reinvestment</span>
            <span className="specsValue">50%</span>
         </li>
      </ul>
   );
}
