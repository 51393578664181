export function Thor() {
   return (
      <ul className="planSpecs thor">
         <li>
            <span className="specsTitle">ROI</span>
            <span className="specsValue">0.5% DAILY</span>
         </li>
         <li>
            <span className="specsTitle">Minimum Deposit</span>
            <span className="specsValue">0.1 BNB</span>
         </li>
         <li>
            <span className="specsTitle">Maximum Deposit</span>
            <span className="specsValue">No Limit</span>
         </li>
         <li>
            <span className="specsTitle">Maximum ROI</span>
            <span className="specsValue">No Limit</span>
         </li>
         <li>
            <span className="specsTitle">Reinvestment</span>
            <span className="specsValue">10%</span>
         </li>
      </ul>
   );
}
