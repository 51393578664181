import bsc from "./assets/bsc.png";
import bnb from "./assets/bnb.png";
import jotform from "./assets/jotform.png";
import help from "./assets/help.png";
import linktee from "./assets/linktee.png";
import tel from "./assets/tel.png";
import bgTimer from "./assets/timer-bg.jpg";

import active from "./assets/active.png";
import inactive from "./assets/inactive.png";
import thorLogo from "./assets/thor-icon.jpg";

const Images = {
   bsc,
   bnb,
   jotform,
   help,
   linktee,
   tel,
   bgTimer,
   active,
   inactive,
   thorLogo,
};

export default Images;
