import { useEffect, useRef, useState } from "react";
import { now, timeParts } from "../utils/time_util";

export function useTimer(origin: number) {
   const [distance, setDistance] = useState(0);
   const [tick, setTick] = useState(false);

   const countdown = useRef(origin > now());

   useEffect(() => {
      const int = setInterval(() => {
         if (countdown.current && origin <= now()) {
            setTick(true);
            clearInterval(int);
         } else {
            setDistance(Math.abs(now() - origin));
         }
      }, 1000);
      countdown.current = origin > now();
      return () => clearInterval(int);
   }, [origin]);

   return [...timeParts(distance), tick];
}
