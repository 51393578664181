import Links from "../../components/Links/Links";
import styles from "./AboutPage.module.css";

export function AboutPage() {
   return (
      <div className={styles.wrap}>
         <p>BMP is a leading brand in creating DeFi applications.</p>
         <div className={styles.links}>
            {/* <a
               href="https://bnbminingpirates.com/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc"
               target={"_blank"}
               rel="noreferrer"
            >
               Mining Pirates
            </a>
            <a
               href="https://fandfbnb.com/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc"
               target={"_blank"}
               rel="noreferrer"
            >
               Fast And Furious
            </a>
            <a
               href="https://paperhouseminer.com/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc"
               target={"_blank"}
               rel="noreferrer"
            >
               Paper House
            </a>
            <a
               href="https://bmpworldcup.com/app?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc"
               target={"_blank"}
               rel="noreferrer"
            >
               BMP WorldCup
            </a>
            <a
               href="https://wadjetegyptianminer.com/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc"
               target={"_blank"}
               rel="noreferrer"
            >
               Wadget Egyption Miner
            </a> */}
         </div>
         <Links />
         <a href="#">View This App on BSCScan.org</a>
         <div className={styles.footer}>Copyright 2023. BMP Group</div>
      </div>
   );
}
