import "./App.css";
import { SideNav } from "./components/SideNav/SideNav";
import { DashboardRoutes } from "./DashboardRoutes";
import { NavBar } from "./components/NavBar/NavBar";
import { BrowserRouter } from "react-router-dom";
import { WalletConnect } from "./components/WalletConnect/WalletConnect";
import { useEffect, useState } from "react";
import { ErrorMessage } from "./components/ErrorMessage/ErrorMessage";

declare var window: any;
let polling = 0;

function App() {
   const [ethereum, setEthereum] = useState(false);
   const [error, setError] = useState("");

   useEffect(() => {
      const interval = window.setInterval(() => {
         if (window.ethereum) {
            setEthereum(true);
            window.clearInterval(interval);
         } else {
            polling++;
            if (polling === 10) {
               setError("No wallet provider was found. Please install a wallet app or extension");
               window.clearInterval(interval);
            }
         }
      }, 500);
      return () => window.clearInterval(interval);
   }, []);

   if (error) return <ErrorMessage msg={error} />;
   if (!ethereum) return <p>Loading wallet...</p>;
   return (
      <BrowserRouter>
         <NavBar />
         <SideNav />
         <div className="content">
            <WalletConnect />
            <DashboardRoutes />
         </div>
      </BrowserRouter>
   );
}

export default App;
