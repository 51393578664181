import { OpenDirOptions } from "fs";
import { ReactNode } from "react";
import Images from "../../images";
import { Button } from "../Button/Button";
import styles from "./Card.module.css";

interface CardProps {
   id: number;
   title?: string;
   children?: ReactNode;
   theme?: "light" | "dark";
   status: "disabled" | "active" | "open";
   onLinkClick?: (id: number) => void;
}

export function Card({ title, children, theme = "light", id, onLinkClick, status }: CardProps) {
   return (
      <div className={`${styles["basic-card"]}`}>
         <div className={styles["card-content"]}>
            {
               <img
                  className={styles.status}
                  src={status !== "disabled" ? Images.active : Images.inactive}
                  alt="active"
               />
            }
            <span className={styles["card-title"]}>{title} BNB</span>
            <div className={styles["card-body"]}>{children}</div>
         </div>

         {status === "open" && (
            <div className={`styles["card-link"] center`}>
               <Button title="Withdraw" onClick={() => onLinkClick && onLinkClick(id)}>
                  <span>Withdraw</span>
               </Button>
            </div>
         )}
      </div>
   );
}
