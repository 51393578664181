export function Hawkeye() {
   return (
      <ul className="planSpecs hawkeye">
         <li>
            <span className="specsTitle">Deposit</span>
            <span className="specsValue">0.5 BNB</span>
         </li>
         <li>
            <span className="specsTitle">100 DAYS</span>
            <span className="specsValue">1.0 BNB</span>
         </li>
         <li>
            <span className="specsTitle">150 DAYS</span>
            <span className="specsValue">2.0 BNB</span>
         </li>
         <li>
            <span className="specsTitle">200 DAYS</span>
            <span className="specsValue">3.5 BNB</span>
         </li>
      </ul>
   );
}
