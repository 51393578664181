import { Route, Routes } from "react-router-dom";
import { AboutPage } from "./pages/AboutPage/AboutPage";
import { DepositList } from "./pages/DepositList/DepositList";
import { HomePage } from "./pages/HomePage/HomePage";
import { NewsPage } from "./pages/NewsPage/NewsPage";
import { Plan } from "./utils/contract";

export function DashboardRoutes() {
   return (
      <Routes>
         <Route path="/" element={<HomePage />} />
         <Route path="/about" element={<AboutPage />} />
         <Route path="/news" element={<NewsPage />} />
         <Route path="/1" element={<DepositList key="1" plan={Plan.THOR} />} />
         <Route path="/2" element={<DepositList key="2" plan={Plan.IRONMAN} />} />
         <Route path="/3" element={<DepositList key="3" plan={Plan.CAPTAIN} />} />
         <Route path="/4" element={<DepositList key="4" plan={Plan.HULK} />} />
         <Route path="/5" element={<DepositList key="5" plan={Plan.HAWKEYE} />} />
         <Route path="/6" element={<DepositList key="6" plan={Plan.BLACKWIDOW} />} />
      </Routes>
   );
}
