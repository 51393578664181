import { useEffect, useState } from "react";
import { getContractWithSigner } from "../utils/contract";

export function useDepositEvents() {
   const [depositEvent, setDepositEvent] = useState(0);
   const [withdrawEvent, setWithdrawEvent] = useState(0);

   useEffect(() => {
      const contract = getContractWithSigner();
      const listener = (
         _address: string,
         _amount: number,
         _plan: number,
         _duration: number,
         event: any
      ) => {
         console.log("new Deposit");
         setTimeout(() => {
            setDepositEvent(event.transactionHash);
         }, 7000);
      };
      contract.on("Deposit", listener);
      return () => {
         contract.removeListener("Deposit", listener);
      };
   }, []);

   useEffect(() => {
      const contract = getContractWithSigner();
      const listener = (
         _address: string,
         _amount: number,
         _plan: number,
         _duration: number,
         event: any
      ) => {
         console.log("new Withdrawal");
         setTimeout(() => {
            setWithdrawEvent(event.transactionHash);
         }, 7000);
      };
      contract.on("Withdraw", listener);
      return () => {
         contract.removeListener("Withdraw", listener);
      };
   }, []);

   return { depositEvent, withdrawEvent };
}
