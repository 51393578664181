import { useWeb3React } from "@web3-react/core";
import { utils } from "ethers";
import { useEffect, useState } from "react";
import { Deposit, getContractWithSigner, Plan } from "../utils/contract";
import { useDepositEvents } from "./useEvents";

export function useDashboard(plan: Plan) {
   const { account } = useWeb3React();
   const { depositEvent, withdrawEvent } = useDepositEvents();

   const [deposits, setDeposits] = useState<Deposit[]>([] as Deposit[]);
   const [dividends, setDividends] = useState("");
   const [checkpoint, setCheckpoint] = useState(0);
   const [planLoading, setPlanLoading] = useState(false);
   const [dividendsLoading, setDividendsLoading] = useState(false);
   const [checkpointLoading, setcheckpointLoading] = useState(false);

   useEffect(() => {
      if (!account) return;
      setPlanLoading(true);

      const contract = getContractWithSigner();
      async function fetchPlan() {
         let planMethod;
         if (plan < 5) {
            planMethod = contract.getROIDeposits;
         } else planMethod = contract.getTimePacks;
         const data = await planMethod(account);
         setPlanLoading(false);
         setDeposits(
            data.map((d: any) => ({
               amount: utils.formatEther(d.amount),
               startTime: d.startTime.toNumber(),
               plan: d.plan,
               duration: d.plan >= 5 ? d.duration : null,
               ended: d.plan >= 5 ? d.withdrawn : null,
            }))
         );
      }
      fetchPlan();
   }, [depositEvent, account, plan]);

   useEffect(() => {
      if (!account) return;
      setcheckpointLoading(true);
      setDividendsLoading(true);
      const contract = getContractWithSigner();
      async function fetchDividends() {
         const dividends = await contract.getDividends(account, plan);
         setDividendsLoading(false);
         setDividends(utils.formatEther(dividends));
      }
      async function fetchCheckpoint() {
         const data = await contract.withdrawCheckpoint(account);
         setcheckpointLoading(false);
         setCheckpoint(data.toNumber());
      }
      fetchCheckpoint();
      fetchDividends();
   }, [depositEvent, withdrawEvent, account]);

   return {
      loading: planLoading || dividendsLoading || checkpointLoading,
      deposits: deposits.map((d, i) => ({ ...d, index: i })).filter((d) => d.plan === plan),
      dividends,
      checkpoint,
   };
}
