import { ethers, utils } from "ethers";
import abi from "./abi.json";
import { getSigner, getMainProvider, getTestProvider } from "./walletApi";

export const contractAddress = "0x5A95Ea31e81d484f4c92D184c69840adB9b7CF51";

export const getContractWithoutSigner = () => {
   const provider =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
         ? getTestProvider()
         : getMainProvider();
   const contract = new ethers.Contract(contractAddress, abi as any, provider);
   return contract;
};

export const getContractWithSigner = () => {
   const contract = new ethers.Contract(contractAddress, abi as any, getSigner());

   return contract;
};

export enum Plan {
   THOR = 1,
   IRONMAN,
   CAPTAIN,
   HULK,
   HAWKEYE,
   BLACKWIDOW,
}

export interface Deposit {
   amount: string;
   startTime: number;
   plan?: number;
   duration?: number;
   withdrawn?: boolean;
}

export const withdrawPlan = async (plan: Plan, id?: number | null, callback?: () => void) => {
   const contract = getContractWithSigner();
   let withdrawMethod;
   if (plan <= Plan.HULK) {
      withdrawMethod = contract.withdrawROI;
   } else {
      withdrawMethod = contract.withdrawTimePack;
   }

   try {
      if (plan <= Plan.HULK) await withdrawMethod(plan);
      else await withdrawMethod(id);
   } finally {
      callback && callback();
   }
};

export const depositPlan = async (
   plan: Plan,
   referrer: string,
   amountEth: string,
   duration?: number | null,
   callback?: () => void
) => {
   const contract = getContractWithSigner();
   let depositMethod;
   if (plan <= Plan.HULK) {
      depositMethod = contract.depositROI;
   } else {
      depositMethod = contract.depositTimePack;
   }
   try {
      if (plan <= Plan.HULK) {
         await depositMethod(referrer, plan, {
            value: ethers.utils.parseEther(amountEth),
            gasLimit: 6000000,
         });
      } else {
         await depositMethod(referrer, plan, duration, {
            value: ethers.utils.parseEther(amountEth),
            gasLimit: 6000000,
         });
      }
   } finally {
      callback && callback();
   }
};

export async function getDeposits(
   address: string
): Promise<{ roiDeposits: Deposit[]; timePacks: Deposit[] }> {
   const contract = getContractWithSigner();

   const [roiDeposits, timePacks] = await Promise.all([
      contract.getROIDeposits(address),
      contract.getTimePacks(address),
   ]);

   return {
      roiDeposits: roiDeposits.map((d: any) => ({
         amount: utils.formatEther(d.amount),
         startTime: d.startTime.toNumber(),
         plan: d.plan,
      })),
      timePacks: timePacks.map((d: any) => ({
         amount: utils.formatEther(d.amount),
         startTime: d.startTime.toNumber(),
         plan: d.plan,
         duration: d.duration,
         withdrawn: d.withdrawn,
      })),
   };
}

export async function getNewbieEvents() {
   // const bsc_jsonRPC_testnet = "https://data-seed-prebsc-1-s1.binance.org:8545/" // json RPC url
   // const provider = new ethers.providers.JsonRpcProvider(bsc_jsonRPC_testnet) // provider for signing transaction
   // const provider = new ethers.providers.Web3Provider((window as any).ethereum);
   const contract = getContractWithSigner();
   const filter = contract.filters.buyEvent();
   console.log(await contract.queryFilter(filter, 24856033, 24856033 + 4000));
   // return events;
}

export async function getReferralLevels(userAddress: string) {
   const contract = getContractWithSigner();
   const levels = await contract.getUserLevels(userAddress);
   return levels.map((x: any) => x.toNumber());
}
