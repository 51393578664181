import styles from "./Loading.module.css";

export function Loading() {
   return (
      <div className={styles.text}>
         Loading
         <span className={styles.bullets}>.</span>
         <span className={styles.bullets}>.</span>
         <span className={styles.bullets}>.</span>
      </div>
   );
}
