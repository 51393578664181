import "./DepositList.css";
import { Plan, withdrawPlan } from "../../utils/contract";
import { useDashboard } from "../../hooks/useDashboard";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Card } from "../../components/Card/Card";
import { DAY, now } from "../../utils/time_util";
import { Progress } from "../../components/Progress/Progress";
import { Withdraw } from "../../components/Withdraw/Withdraw";
import { NewDeposit } from "../../components/NewDeposit/NewDeposit";
import { Loading } from "../../components/Loading/Loading";
import { Thor } from "./Thor";
import { IronMan } from "./IronMan";
import { Captain } from "./Camptain";
import { Hulk } from "./Hulk";
import { Hawkeye } from "./Hawkeye";
import { BlackWidow } from "./BlackWidow";

function getRoiLength(plan: Plan) {
   switch (plan) {
      case Plan.THOR:
         return Number.MAX_SAFE_INTEGER;
      case Plan.IRONMAN:
         return 236 * DAY;
      case Plan.CAPTAIN:
         return 125 * DAY;
      case Plan.HULK:
         return 54 * DAY;
      default:
         throw new Error("Invalid plan");
   }
}

function getCardStatus(d: any, plan: Plan) {
   if (plan === Plan.THOR) return "active";
   if (plan >= 5) {
      if (d.ended) return "disabled";
      if (d.startTime + d.duration * DAY <= now()) return "open";
      return "active";
   }
   if (d.startTime + getRoiLength(plan) < now()) return "disabled";
   return "active";
}

export function DepositList({ plan }: { plan: Plan }) {
   const { account } = useWeb3React();
   const { loading, deposits, dividends, checkpoint } = useDashboard(plan);
   const [, setWithdrawLoader] = useState(false);

   if (!plan || plan < 0 || plan > 6)
      return <h1>An error accured. Please contact webiste admin.</h1>;

   const handleWithdraw = (id: number) => {
      setWithdrawLoader(true);
      if (account && plan) withdrawPlan(plan, id, () => setWithdrawLoader(false));
   };
   return (
      <>
         {plan === Plan.THOR ? (
            <Thor />
         ) : plan === Plan.IRONMAN ? (
            <IronMan />
         ) : plan === Plan.CAPTAIN ? (
            <Captain />
         ) : plan === Plan.HULK ? (
            <Hulk />
         ) : plan === Plan.HAWKEYE ? (
            <Hawkeye />
         ) : (
            <BlackWidow />
         )}
         {plan < Plan.HAWKEYE &&
            deposits.filter((d) => d.startTime + getRoiLength(plan) >= now()).length > 0 && (
               <Withdraw
                  available={dividends.substring(0, 7)}
                  plan={plan}
                  checkpoint={checkpoint}
               />
            )}
         <NewDeposit plan={plan} />
         <h1>Packages List: {deposits.length}</h1>
         {loading ? (
            <Loading />
         ) : deposits.length > 0 ? (
            deposits.map((d) => (
               <Card
                  title={d.amount}
                  id={d.index}
                  theme={"dark"}
                  key={d.startTime}
                  onLinkClick={() => handleWithdraw(d.index)}
                  status={getCardStatus(d, plan)}
               >
                  {plan !== Plan.THOR && (
                     <Progress
                        percent={
                           ((now() - d.startTime) /
                              (plan >= 5 && d.duration ? d.duration * DAY : getRoiLength(plan))) *
                           100
                        }
                        text={plan >= 5 ? d.duration + " days" : getRoiLength(plan) / DAY + " days"}
                     />
                  )}
               </Card>
            ))
         ) : (
            <p>This plan is not active for you. Please use the form abow to buy this plan.</p>
         )}
      </>
   );
}
