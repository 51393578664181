import { ethers } from "ethers";

export const getSigner = () => {
   const provider = new ethers.providers.Web3Provider((window as any).ethereum);
   return provider.getSigner();
};

export function getMainProvider() {
   return new ethers.providers.JsonRpcProvider("https://bsc-dataseed3.binance.org/");
}

export function getTestProvider() {
   return new ethers.providers.JsonRpcProvider("https://data-seed-prebsc-1-s1.binance.org:8545/");
}

export function getEnvProvider() {
   return new ethers.providers.Web3Provider((window as any).ethereum);
}
