/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from "./Links.module.css";
import Images from "../../images";
import { contractAddress } from "../../utils/contract";

const Links = () => {
   // const [showHelp, setShowHelp] = useState(false);

   return (
      <div className={styles.wrap}>
         <a href="https://t.me/BMPBrandDDB" className={styles.btn}>
            <img src={Images.tel} alt="Global" />
            <div>Official Channel</div>
         </a>
      </div>
   );
};

export default Links;
