export function Captain() {
   return (
      <ul className="planSpecs captain">
         <li>
            <span className="specsTitle">ROI</span>
            <span className="specsValue">2.4% DAILY</span>
         </li>
         <li>
            <span className="specsTitle">Minimum Deposit</span>
            <span className="specsValue">1.0 BNB</span>
         </li>
         <li>
            <span className="specsTitle">Maximum Deposit</span>
            <span className="specsValue">100 BNB</span>
         </li>
         <li>
            <span className="specsTitle">Maximum ROI</span>
            <span className="specsValue">180%</span>
         </li>
         <li>
            <span className="specsTitle">Reinvestment</span>
            <span className="specsValue">40%</span>
         </li>
      </ul>
   );
}
