import { useWeb3React } from "@web3-react/core";
import { utils } from "ethers";
import { ChangeEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { depositPlan, Plan } from "../../utils/contract";
import { Button } from "../Button/Button";
import Input from "../Input/Input";
import styles from "./NewDeposit.module.css";

export function NewDeposit({ plan }: { plan: Plan }) {
   const [depositLoader, setDepositLoader] = useState(false);
   const { account } = useWeb3React();
   const [purchaseAmount, setPurchaseAmount] = useState("");
   const [duration, setDuration] = useState(0);

   function handleBuy() {
      if (plan && referrer) {
         if (plan === Plan.HAWKEYE && ![100, 150, 200].includes(duration)) return;
         if (plan === Plan.BLACKWIDOW && ![200, 250, 300].includes(duration)) return;
         if (!utils.isAddress(referrer)) {
            alert(
               "Please use an invitation link to register. After first purchase you will not need any invitation link."
            );
            return;
         }
         setDepositLoader(true);
         try {
            depositPlan(+plan, referrer, purchaseAmount, duration, () => setDepositLoader(false));
         } finally {
            setDepositLoader(false);
         }
      }
   }

   function handleDurationChange(e: ChangeEvent<HTMLInputElement>) {
      setDuration(+e.target.value);
   }

   const [params] = useSearchParams();
   const referrer = params.get("ref");

   return (
      <div className={`${styles.newDeposit} center`}>
         <h1>Buy This Plan</h1>
         <p style={{ marginBottom: "0px" }}>Enter in BNB</p>
         <Input onChange={(val) => setPurchaseAmount(val)} />

         {plan > 4 && (
            <>
               <h1>Period</h1>
               <div className={styles.container}>
                  <label>
                     <input
                        type="radio"
                        name="duration"
                        value={plan === Plan.HAWKEYE ? 100 : 200}
                        onChange={handleDurationChange}
                     />
                     <span>{plan === Plan.HAWKEYE ? "100" : "200"}</span>
                  </label>
                  <label>
                     <input
                        type="radio"
                        name="duration"
                        value={plan === Plan.HAWKEYE ? 150 : 250}
                        onChange={handleDurationChange}
                     />
                     <span>{plan === Plan.HAWKEYE ? "150" : "250"}</span>
                  </label>
                  <label>
                     <input
                        type="radio"
                        name="duration"
                        value={plan === Plan.HAWKEYE ? 200 : 300}
                        onChange={handleDurationChange}
                     />
                     <span>{plan === Plan.HAWKEYE ? "200" : "300"}</span>
                  </label>
               </div>
            </>
         )}
         <Button disabled={depositLoader} onClick={handleBuy}>
            {depositLoader ? "Loading..." : "Buy"}
         </Button>
      </div>
   );
}
