import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { Button } from "../Button/Button";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import styles from "./WalletConnect.module.css";

export function WalletConnect() {
   const { activate, active, account, error } = useWeb3React();

   function handleConnect() {
      try {
         activate(new InjectedConnector({}));
      } catch (error) {
         alert(error);
      }
   }
   if (error) {
      return <ErrorMessage msg={error.message} />;
   }
   return (
      <div className={styles.connect}>
         {active && account ? (
            <span className={styles.address}>
               Connected: {`${account.substring(0, 8)}...${account.substring(account.length - 6)}`}
            </span>
         ) : (
            <div>
               <p>
                  Wallet is not connected. To use this application you need to connect to your
                  wallet application. If you have not installed it yet, Please install Metamask for
                  your browser or use a wallet application like Trust wallet.
               </p>
               <Button onClick={handleConnect}>Connect</Button>
            </div>
         )}
      </div>
   );
}
