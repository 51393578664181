import styles from "./ReferralView.module.css";
import { Button } from "../Button/Button";
import copy from "copy-to-clipboard";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { fetchNewBieEvents, makeEventsTree } from "../../utils/events-fetch";

export const ReferralView = () => {
   const { account } = useWeb3React();

   const [loadingRefs, setLoadingRefs] = useState(false);
   const [referrals, setReferrals] = useState([] as number[]);

   function handleClick() {
      if (account) {
         copy(`${window.location.host}/?ref=${account}`);
         alert("Your link copied successfully");
      }
   }

   useEffect(() => {
      async function loadRefs() {
         try {
            if (!account) return;
            setLoadingRefs(true);
            const events = await fetchNewBieEvents();
            setReferrals(makeEventsTree(account, events));
         } finally {
            setLoadingRefs(false);
         }
      }
      loadRefs();
   }, [account]);

   return (
      <>
         <br></br>
         <h1>Your referrals</h1>
         <div className={styles.wrap}>
            <div className={styles["check-left-line"]}></div>
            <ul className={styles["checks"]}>
               <li className={referrals[0] > 0 ? styles.active : ""}>
                  <b>{referrals[0]}</b> referrals on level 1
               </li>
               <li className={referrals[1] > 0 ? styles.active : ""}>
                  <b>{referrals[1]}</b> referrals on level 2
               </li>
               <li className={referrals[2] > 0 ? styles.active : ""}>
                  <b>{referrals[2]}</b> referrals on level 3
               </li>
               <li className={referrals[3] > 0 ? styles.active : ""}>
                  <b>{referrals[3]}</b> referrals on level 4
               </li>
            </ul>
         </div>
         <div className="center">
            <Button onClick={handleClick}>Copy Invitation Link</Button>
         </div>
      </>
   );
};
