import { contractAddress } from "./contract";
const topic0 = "0xd6a361e057414473ea703e5cbd99d7016480e4e9b39b36c9c554466f2dbbbfd8";
const apiKey = "T3H2SPWUXKCF51AE2IEMY89VX4PPF27KQ7";
const mainNetwork = "https://api.bscscan.com/";
const testNetwork = "https://api-testnet.bscscan.com/";
const url = `${
   false /*process.env.NODE_ENV === "production"*/ ? mainNetwork : testNetwork //TODO: make this correct
}api?module=logs&action=getLogs&apikey=${apiKey}&address=${contractAddress}&topic0=${topic0}`;

export async function fetchNewBieEvents() {
   const res = await fetch(url);
   console.log(res);
   const events = await res.json();
   console.log(events);
   return events.result.map((d: any) => ({
      user: d.topics[1].replace("000000000000000000000000", ""),
      referrer: d.topics[2].replace("000000000000000000000000", ""),
   }));
}

export function makeEventsTree(user: string, events: { user: string; referrer: string }[]) {
   let root = [user.toLowerCase()];
   let counts = [0, 0, 0, 0];
   for (let i = 0; i < counts.length; i++) {
      const children = events
         .filter((r) => root.includes(r.referrer.toLowerCase()))
         .map((r) => r.user.toLowerCase());
      if (children.length === 0) break;
      counts[i] = children.length;
      root = children;
   }
   return counts;
}
