export function IronMan() {
   return (
      <ul className="planSpecs ironman">
         <li>
            <span className="specsTitle">ROI</span>
            <span className="specsValue">1.3% DAILY</span>
         </li>
         <li>
            <span className="specsTitle">Minimum Deposit</span>
            <span className="specsValue">0.5 BNB</span>
         </li>
         <li>
            <span className="specsTitle">Maximum Deposit</span>
            <span className="specsValue">No Limit</span>
         </li>
         <li>
            <span className="specsTitle">Maximum ROI</span>
            <span className="specsValue">260%</span>
         </li>
         <li>
            <span className="specsTitle">Reinvestment</span>
            <span className="specsValue">15%</span>
         </li>
      </ul>
   );
}
