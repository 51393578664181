export function BlackWidow() {
   return (
      <ul className="planSpecs widow">
         <li>
            <span className="specsTitle">Deposit</span>
            <span className="specsValue">5.0 BNB</span>
         </li>
         <li>
            <span className="specsTitle">200 DAYS</span>
            <span className="specsValue">20 BNB</span>
         </li>
         <li>
            <span className="specsTitle">250 DAYS</span>
            <span className="specsValue">25 BNB</span>
         </li>
         <li>
            <span className="specsTitle">400 DAYS</span>
            <span className="specsValue">35 BNB</span>
         </li>
      </ul>
   );
}
