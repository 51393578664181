import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import Label from "../../components/Label/Label";
import { Loading } from "../../components/Loading/Loading";
import { ReferralView } from "../../components/ReferralView/ReferralView";
import { getDeposits } from "../../utils/contract";

export function HomePage() {
   const { account } = useWeb3React();
   const [stats, setStats] = useState({ totalRoi: 0, timePacks: 0, totalInvested: 0 });
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(false);

   useEffect(() => {
      async function loadDeposits() {
         if (account) {
            try {
               setLoading(true);
               const result = await getDeposits(account);
               setStats({
                  totalRoi: result.roiDeposits.length,
                  timePacks: result.timePacks.length,
                  totalInvested:
                     result.roiDeposits.reduce(
                        (p, c: { amount: string }) => p + parseFloat(c.amount),
                        0
                     ) +
                     result.timePacks.reduce(
                        (p, c: { amount: string }) => p + parseFloat(c.amount),
                        0
                     ),
               });
            } catch (error) {
               setError(true);
               throw error;
            } finally {
               setLoading(false);
            }
         }
      }
      loadDeposits();
   }, [account]);

   return (
      <>
         {loading && <Loading />}
         {error ? (
            <ErrorMessage msg="Could not load data. Please reload the page." />
         ) : stats.totalInvested == 0 ? (
            <p>
               You do not have active packages yet. You can buy any packges using the right menu.
            </p>
         ) : (
            <>
               <Label
                  label="Total ROI Packages"
                  value={stats.totalRoi.toString()}
                  showBorder={true}
               />
               <br />
               <Label
                  label="Total Time Packages"
                  value={stats.timePacks.toString()}
                  showBorder={true}
               />
               <br />
               <Label
                  label="Total Invested"
                  value={stats.totalInvested.toString().substring(0, 7)}
                  showBorder={true}
               />
               <ReferralView />
            </>
         )}
      </>
   );
}
