import { useEffect } from "react";
import { useTimer as useCounter } from "../../hooks/useTimer";
import styles from "./Counter.module.css";

export function Counter({ origin, onEnd }: { origin: number; onEnd?: () => void }) {
   const [days, hours, mins, secs, tick] = useCounter(origin);

   useEffect(() => {
      if (tick && onEnd) onEnd();
   }, [tick]);

   return (
      <div className={styles.wrap}>
         <div className={styles.daysWrap}>
            <span className={styles.digits}>{days < 0 ? "0" : days}</span>
            <span className={styles.label}>DAYS</span>
         </div>
         <div className={styles.hoursWrap}>
            <span className={styles.digits}>{hours < 0 ? "0" : hours}</span>
            <span className={styles.label}>HOURS</span>
         </div>
         <div className={styles.minsWrap}>
            <span className={styles.digits}>{mins < 0 ? "0" : mins}</span>
            <span className={styles.label}>MINS</span>
         </div>
         <div className={styles.secsWrap}>
            <span className={styles.digits}>{secs < 0 ? "0" : secs}</span>
            <span className={styles.label}>SECS</span>
         </div>
      </div>
   );
}
