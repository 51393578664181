import { Button } from "../Button/Button";
import { Plan, withdrawPlan } from "../../utils/contract";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import styles from "./Withdraw.module.css";
import { DAY, now } from "../../utils/time_util";
import { Counter } from "../Counter/Counter";

export function Withdraw({
   plan,
   available,
   checkpoint,
}: {
   plan: Plan;
   available: string;
   checkpoint: number;
}) {
   const [withdrawLoader, setWithdrawLoader] = useState(false);
   const [, setDummy] = useState(0);
   const { account } = useWeb3React();

   function handleWithdraw() {
      setWithdrawLoader(true);
      if (account && plan) withdrawPlan(plan, null, () => setWithdrawLoader(false));
   }

   return (
      <div className={`${styles.wrap} center`}>
         <div className={styles.label}>Withdrawable</div>
         <div className={`${styles.value} number`}>{available} BNB</div>

         {checkpoint + DAY <= now() ? (
            <Button disabled={withdrawLoader} onClick={handleWithdraw}>
               {withdrawLoader ? "Loading..." : "Withdraw"}
            </Button>
         ) : (
            <Counter
               origin={checkpoint + DAY}
               onEnd={() => {
                  setDummy(Math.random);
               }}
            />
         )}
      </div>
   );
}
