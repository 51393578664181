import { NavLink, useSearchParams } from "react-router-dom";
import styles from "./SideNav.module.css";

export function SideNav() {
   const [params] = useSearchParams();
   const referrer = params.get("ref");
   return (
      <div className={styles.menuContainer}>
         <div>
            <ul>
               <li className={styles.home}>
                  <NavLink
                     to={`/?ref=${referrer}`}
                     className={({ isActive }) => (isActive ? styles.active : "")}
                  >
                     <span className={styles.month}>HOME</span>
                  </NavLink>
               </li>
               <li className={styles.thor}>
                  <NavLink
                     to={`/1?ref=${referrer}`}
                     className={({ isActive }) => (isActive ? styles.active : "")}
                  >
                     <span className={styles.month}>thor</span>
                  </NavLink>
               </li>
               <li className={styles.ironman}>
                  <NavLink
                     to={`/2?ref=${referrer}`}
                     className={({ isActive }) => (isActive ? styles.active : "")}
                  >
                     <span className={styles.month}>ironman</span>
                  </NavLink>
               </li>
               <li className={styles.captain}>
                  <NavLink
                     to={`/3?ref=${referrer}`}
                     className={({ isActive }) => (isActive ? styles.active : "")}
                  >
                     <span className={styles.month}>captain.a</span>
                  </NavLink>
               </li>
               <li className={styles.hulk}>
                  <NavLink
                     to={`/4?ref=${referrer}`}
                     className={({ isActive }) => (isActive ? styles.active : "")}
                  >
                     <span className={styles.month}>hulk</span>
                  </NavLink>
               </li>
               <li className={styles.hawkeye}>
                  <NavLink
                     to={`/5?ref=${referrer}`}
                     className={({ isActive }) => (isActive ? styles.active : "")}
                  >
                     <span className={styles.month}>hawkeye</span>
                  </NavLink>
               </li>
               <li className={styles.widow}>
                  <NavLink
                     to={`/6?ref=${referrer}`}
                     className={({ isActive }) => (isActive ? styles.active : "")}
                  >
                     <span className={styles.month}>b.widow</span>
                  </NavLink>
               </li>
               <li className={styles.message}>
                  <NavLink
                     to={`/news?ref=${referrer}`}
                     className={({ isActive }) => (isActive ? styles.active : "")}
                  >
                     <span className={styles.month}>News</span>
                  </NavLink>
               </li>
               <li className={styles.help}>
                  <NavLink to={`/about?ref=${referrer}`}>
                     <span className={styles.month}>About</span>
                  </NavLink>
               </li>
            </ul>
         </div>
      </div>
   );
}
